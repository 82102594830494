

import React, { useState } from "react";
import FileCreateCX from "../webx/srvc-filecreate-cweb-xx";
import { AssetMediaSave } from "../../services/srvc-assets-realm";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import axios from "axios"; // Import axios for making HTTP requests

export default function AssetAddMedia() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const { item } = useParams();
  const [loader, setLoader] = useState(false);
  const [Url, SetUrl] = useState("");
  const [file, setfile] = useState(null);
  const [type, setType] = useState(null);
  const[name,setName]=useState(null)
  const navigate = useNavigate();

  const handleFile = async (item) => {
    setfile(item.file);
    setType(item.type);
    setName(item.name)
  };


  const handleSubmit = async () => {
    setLoader(true);
    var datx = {
      asset: item,
      user: asset.item,
    };

    if (type == "video/mp4") {
      try {
        setLoader(true);

        // Upload file to Cloudinary
        const formData = new FormData();
        // formData.append("file", item.file);
        // formData.append("upload_preset", "your_cloudinary_upload_preset"); // Replace with your Cloudinary upload preset

        formData.append("file", file);
        formData.append("upload_preset", "docsApp");
        formData.append("cloud_name", "dlwiberso");

        const fileResponse = await axios.post(
          "https://api.cloudinary.com/v1_1/dlwiberso/video/upload",
          formData
        );

        const fileUrl = fileResponse.data.url;
        datx.name = name;
        datx.type= "remote"
        datx.media = {
          link: fileUrl || "*****",
          mime: type
        };
        setLoader(false);
      } catch (error) {
        console.error("Error uploading file to Cloudinary:", error);
        setLoader(false);
      }
    }
    else{
      datx.file = file;
      datx.type= "local"
    }
    
      var res = await AssetMediaSave({ data: datx });
      if (res.stat) navigate(`/team/assets/${item}`);
      setLoader(false);
  };

  return (
    <div>
      <div>
        {loader ? (
          <div
            className="p-3  rounded-xd d-flex align-items-center  justify-content-center "
            style={{ marginTop: "200px" }}
          >
            <p className="P-4 m-0 mx-4 fw-bold">Please wait ....</p>
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="p-3 back-color-wite rounded-xd mt-2 ">
            <FileCreateCX
              media={handleFile}
              accept="image/png, image/jpg, image/jpeg, video/mp4"
            />
            <div className="d-flex mt-3 justify-content-end">
              <div className="text-end mt-2">
                <button
                  onClick={() => handleSubmit()}
                  className={`btn btn-primary rounded-xx text-small `}
                  disabled={file === null}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}







