import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ContentFormat from "../content/webz/content-format-xv";
import "./room.css";
import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";
import WebbDateTimeMedium from "../content/webx/webb-datetime";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import { useEffect, useState } from "react";
import Inputbox from "../content/rooms/rooms-inputBox";
import { useNavigate } from "react-router-dom";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";
// import "./room.css";
// import UserInfocardMobileModule from "../content/user/user-infocard-mobile";
// import UserAccountBalance from "../content/user/user-account-balance";
import WebbIconBack from "../content/webx/webb-icon-back";
import MenubarFormat from "../content/webz/menubar-format-xm";
import ChatList from "../content/rooms/rooms-chatList";
import Chatbot from "../content/rooms/rooms-chatBox";
import TeamInfocardHomeModule from "../content/user/team-infocard-home";
import WebbMenuMobile from "../content/webz/webb-menu-xv";
import MenuButtons from "../content/webx/menu-buttons";

const metadata = {
  name: "Rooms",
  banner: {
    link: "https://img.freepik.com/free-vector/abstract-banner-with-world-map_1048-12232.jpg?&w=996",
  },
};

export default function Rooms() {
  const [user, setUser] = useState(null);
  const [roomselected, setRoomSelected] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const router = useNavigate();
  useEffect(() => {
    const asset = JSON.parse(localStorage.getItem("indx"));
    setUser(asset);
  }, []);

  const handleRoomSelect = async (item) => {
    setRoomSelected(item);
    setRefresh(!refresh);
    setShow(true);
  };

  const handleRefresh = async (item) => {
    setRefresh(!refresh);
  };

  const handleNewRoomSelected = async (item) => {
    const res = await fetch("/api/room-details", {
      body: JSON.stringify({ data: { item: item }, srvc: "******" }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });
    var result = JSON.parse(await res.json());
    if (result.stat) {
      setTimeout(() => {
        setRoomSelected(result.data);
      }, 2000);
    }
  };

  const ResetRoomSelection = async () => {
    setRoomSelected(null);
  };

  // const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  useEffect(() => {
    const asset = JSON.parse(localStorage.getItem("indx"));
    const bznx = JSON.parse(localStorage.getItem("bznx"));
    setUser(asset ? asset : bznx);
  }, [refresh]);

  const AccountLogout = async () => {
    setRefresh(!refresh);
    router("/auth/x");
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />{" "}
      </Helmet>
      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <WebbIcon
                data={{ color: "text-color-main", size: "text-icon-sm" }}
              />
            </>
          ),
          data: (
            <>
              <WebbHeader />
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              {/* {!show ? (
                <Chathistory
                  selectroom={handleRoomSelect}
                  handleRefresh={handleRefresh}
                  handleRoomReset={ResetRoomSelection}
                  setShow={setShow}
                />
              ) : (
                ""
              )} */}
              <div className="d-none d-md-block">
                <ChatList
                  selectroom={handleRoomSelect}
                  handleRefresh={handleRefresh}
                  handleRoomReset={ResetRoomSelection}
                  setShow={setShow}
                />
              </div>
              <div className="d-none d-md-block">
                <WebbDividerSmall />
              </div>
            </>
          ),
          footer: (
            <>
              {/* <div className="text-small"> */}
              <WebbDateTimeMedium />
              {/* </div> */}
            </>
          ),
        }}
        content={{
          header: (
            <>
              <div className="d-flex justify-content-between justify-content-md-start align-items-start">
                <div className="d-flex gap-4">
                  {!show ? (
                    <i
                      data-bs-toggle="offcanvas"
                      href="#offcanvasExample"
                      role="button"
                      aria-controls="offcanvasExample"
                      className="bx bx-menu d-block d-md-none"
                      style={{ fontSize: "25px" }}
                    ></i>
                  ) : (
                    <div
                      className="cursor d-md-none d-block"
                      style={{ position: "relative", bottom: "5px" }}
                      onClick={() => {
                        setShow(!show);
                        setRoomSelected(null);
                      }}
                    >
                      <WebbIconBack
                        data={{
                          color: "text-color-tone",
                          size: "text-icon-sm",
                        }}
                      />
                    </div>
                  )}
                  <div
                    className="offcanvas offcanvas-start w-75"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="offcanvas-header">
                      <h6
                        className="offcanvas-title"
                        id="offcanvasExampleLabel"
                      >
                        Miniland Creator
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body d-flex flex-column gap-4">
                      <TeamInfocardHomeModule />
                      <WebbMenuMobile />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          width: "88%",
                        }}
                      >
                        <MenuButtons />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="">{roomselected?.name || metadata.name}</p>
                </div>
                <div
                  className="d-block d-md-none"
                  style={{ position: "relative", bottom: "6px" }}
                >
                  <UserAvatar />
                </div>
              </div>
            </>
          ),
          data: (
            <div
              className="d-flex flex-column justify-content-between position-relative mainBox p-1"
              style={{ height: "90vh" }}
            >
              <div className={`first-component d-lg-none d-block`}>
                {!show ? (
                  <ChatList
                    selectroom={handleRoomSelect}
                    handleRefresh={handleRefresh}
                    handleRoomReset={ResetRoomSelection}
                    refresh={refresh}
                    // dismissOffcanvas={true}
                  />
                ) : (
                  ""
                )}
              </div>
              <Chatbot
                room={roomselected}
                refresh={refresh}
                handleRefresh={handleRefresh}
              />
              {show ? (
                <Inputbox
                  room={roomselected}
                  refresh={refresh}
                  newroomselect={handleNewRoomSelected}
                  handleRefresh={handleRefresh}
                />
              ) : (
                ""
              )}
            </div>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: <>{/* 4th section */}</>,
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
