import React, { useEffect, useState } from "react";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { useNavigate, useParams } from "react-router-dom";
import { AssetsBrokerAdd, AssetsList } from "../../services/srvc-assets-realm";
import FormNeeded from "../webx/form-needed";
import { BrokersList } from "../../services/srvc-brokers-realm";
import axios from "axios";
import { validateNumberInput } from "../webx/validate-num-input";

export default function AssetAddBroker() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const { item } = useParams();
  const [assetId, setAssetId] = useState(item);

  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [assets, setAssets] = useState([]);
  const [brokers, setBrokers] = useState([]);

  const navigate = useNavigate();

  const [data, setData] = useState({
    asset: "",
    broker: "",
    percentage: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AssetsList({
          data: {
            owner: asset.item,
          },
        });

        if (res?.data?.list) {
          setAssets(res.data.list);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await BrokersList({
          data: {
            items: "20",
            index: "1",
          },
        });

        if (res?.data?.list) {
          setBrokers(res.data.list);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = () => {
    const fetchData = async () => {
      try {
        const res = await AssetsBrokerAdd({
          data: {
            asset: data.asset,
            broker: data.broker,
            feex: {
              number: data.percentage,
            },
          },
        });
        if (res.stat) {
          alert("Broker Added");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  };
  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };
  const handlePercentageChange = (event) => {
    // let value = parseFloat(event.target.value);
    // if (value < 1) {
    //   value = 1;
    // } else if (value > 50) {
    //   value = 50;
    // }
    let value = validateNumberInput(event.target.value, 0,50)
    handleChange("percentage", value);
  };

  if (loader == true) {
    return (
      <p className="mt-4 d-flex justify-content-center">Please wait ....</p>
    );
  }
  console.log(data);

  return (
    <div className="container">
      <div
        className="form-container d-flex  flex-column "
        style={{
          margin: "50px auto",
          backgroundColor: " #ffffff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="form-group mb-3">
          <label className="form-label small">
            Asset <FormNeeded />
          </label>
          <select
            className="form-select rounded-none"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data.asset}
            onChange={({ target }) => handleChange("asset", target.value)}
          >
            <option value="">Select Asset</option>
            {assets.map((item, i) => (
              <option key={i} value={item.item}>
                {item.meta.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">
            Broker <FormNeeded />
          </label>
          <select
            className="form-select rounded-none"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data.broker}
            onChange={({ target }) => handleChange("broker", target.value)}
          >
            <option value="">Select Broker</option>
            {brokers.map((item, i) => (
              <option key={i} value={item.item}>
                {item.meta.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <label className="form-label small">
            Percentage <FormNeeded />
          </label>
          <input
            type="text"
            className="form-control rounded-none"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data.percentage}
            onChange={handlePercentageChange}
            min="0"
            max="50"
            step="0.10"
          />
        </div>

        <div className="d-flex justify-content-end mt-4">
          <button
            type="button"
            className={`btn btn-primary rounded-xx`}
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
