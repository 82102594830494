export function validateNumberInput(
  value,
  min = null,
  max = null,
  length = null
) {
  let numericValue = value.replace(/[^0-9.]/g, "");

  let number = parseFloat(numericValue);

  if (isNaN(number)) {
    number = 0;
  }
  if (length !== null && numericValue.length > length) {
    numericValue = numericValue.substring(0, length);
    number = parseFloat(numericValue);
  }
  if (min !== null && number < min) {
    number = min;
  }
  if (max !== null && number > max) {
    number = max;
  }

  return number;
}
