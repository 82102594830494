import React, { useState } from "react";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import WebbIcon from "../webx/webb-icon";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

export default function UserOverviews({ type, balance }) {

  // console.log

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  // const [balance, setBalance] = useState({
  //   number: bal?.number || "000000",
  //   ticker: bal?.ticker || "******",
  // });
  const [data, setData] = useState();




  const media =
    "https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900";

  return (
    <>
      {/* info */}
      <div
        className={
          type.text == "overview"
            ? ""
            : "d-none"
        }
      >
        <div className="mb-3 d-none">
          <h2 className="text-lead m-0 mx-3">{"Account Details"}</h2>
          <p className="text-normal m-0"></p>
        </div>

        {/* data */}
        <div
          className="rounded-xd text-color-wite shadow"
          style={{
            backgroundImage: `url(${media})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            // minHeight: '100vh'
            // height:'100vh'
          }}
        >
          <div
            className="rounded-xd p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="d-flex" style={{}}>
              <div className="me-auto">
                <p className="text-small m-0 d-none">Account Balance</p>
                <p className="m-0 d-none d-md-block">
                  <span className="text-lead text-bold">
                    {NumberFormat(balance?.number / 1000000 || "0", "", "2")}
                  </span>
                  <span className="ms-1"></span>
                  <span className="text-small">{balance?.ticker || "***"}</span>
                </p>
                <p className="m-0 d-md-none">
                  <span className="text-lead text-bold">
                    {NumberFormat(balance?.number / 1000000 || "0", "", "2")}
                  </span>
                  <span className="ms-1"></span>
                  <span className="text-small">{balance?.ticker || "***"}</span>
                </p>
                <p className="m-0 d-none">{asset?.number || "***"}</p>
              </div>

              <div
                className="rounded-xx px-2 pt-1"
                style={{ height: "2.5rem", width: "2.5rem" }}
              >
                {/* <Jazzicon diameter={33} seed={jsNumberForAddress(asset.item ??= Date.now().toString())} />  */}
                <WebbIcon
                  data={{ color: "text-color-wite", size: "text-icon-sm" }}
                />
              </div>
            </div>

            <WebbDividerMedium />
            <WebbDividerSmall />
            <p className="header-sm m-0">
              {asset?.number || "9999 0000 0000 0456"}
            </p>

            <WebbDividerSmall />
            <p className="text-normal text-sm text-bold m-0">
              {asset?.name || "******"}
            </p>
            <p className="m-0">{asset?.mail || "******"}</p>
            <p className="text-small m-0">
              Status: {asset?.active ? "Active" : "Inactive"}
            </p>
          </div>
        </div>

        {/* <div className="text-small mt-1 text-color-lite mx-3">
        {asset?.item || '******'}
      </div> */}
      </div>
    </>
  );
}
