import axios from "axios";
import services from '../data.services/data-services-main.json'
const base = (services.data.find(x => x.code === 'users.brokers')).link

export const BrokersList = async (item) => {
  
    const basx = base + '/users/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }