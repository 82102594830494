// main
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";
import ContentFormat from "../content/webz/content-format-xv";

import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";

import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../services/srvc-auth-local";

import AssetsSearchModule from "../content/assets/assets-search";

import AssetActionsModule from "../content/assets/assets-actions";
import AssetList from "../content/statistics/asset-list";
import MenubarFormat from "../content/webz/menubar-format-xm";
import WebbDateTimeMedium from "../content/webx/webb-datetime";
import MobileSidebarModule from "../content/sidebar/sidebar-menu";
import FloatButton from "../content/float/float-button";
import FilterModule from "../content/filter/filter-button";

export default function Assets() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [assetSearchData, setAssetSearchData] = useState({});

  const metadata = {
    name: "Assets",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  const handleNetworkSearchChange = (newData) => {
    setAssetSearchData(newData);
  };

  const actionProps = {
    onDataChange: handleNetworkSearchChange,
  };
  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <AssetsSearchModule onDataChange={handleNetworkSearchChange} />
            </>
          ),
          footer: (
            <>
              {" "}
              <WebbDateTimeMedium />
            </>
          ),
        }}
        content={{
          header: (
            <>
              <MobileSidebarModule heading="Digital Assets" />
            </>
          ),
          data: (
            <>
              <FilterModule
                heading="Assets"
                action={AssetsSearchModule}
                actionProps={actionProps}
              />
              <h2 className="text-normal text-color-next mx-3 mb-2 d-none">
                Assets
              </h2>

              <AssetList assetSearchData={assetSearchData} />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <FloatButton action={AssetActionsModule} />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <AssetActionsModule />
            </>
          ),
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
