import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  AssetDetails,
  AssetUnitsCreates,
} from "../../services/srvc-assets-realm";
import FileCreateCX from "../webx/srvc-filecreate-cweb-xx";
import AssetsUnitsCreate from "../../views.assets/assets-create-units";

export default function UnitsCreateModule() {
  const navigate = useNavigate();
  const { item } = useParams();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const creditmode = ["inr"];

  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [memo, setMemo] = useState("");

  const [media, setMedia] = useState({ link: "", mime: "" });
  const [ticker, setTicker] = useState([]);

  const [image, setImage] = useState(null);

  const [assetx, setAssetx] = useState();
  const [data, setData] = useState({
    name: "",
    memo: "",
    count: "",
    rate: "",
    ticker: "inr",
    chain: "416012",
  });

  // useEffect for form validation
  useEffect(() => {
    setForm(false);
    if (
      data.name !== "" &&
      data.memo !== "" &&
      media.link !== "" &&
      parseInt(data.count) > 0 &&
      parseInt(data.rate) > 0
    )
      setForm(true);
  }, [data]);

  const handleSubmit = async () => {
    setLoading(true);
    setSubmit(true);

    var datx = {
      asset: item,
      name: data.name,
      memo: data.memo,
      media: { link: null, image: image },
      units: { number: data.count },
      rate: { number: data.rate, ticker: data.ticker },
    };
    // console.log(datx)
    var result = await AssetUnitsCreates({ data: datx, srvc: asset.item });

    if (result.data) {
      setDone(true);
      navigate(`/team/assets/${item}`);
      setLoading(false);
    }
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleFile = async (item) => {
    setImage(item.file);
  };

  if (loading == true) {
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: "200px" }}
        >
          <p className="P-4 m-0 mx-4 fw-bold">Please wait ....</p>
          <div className="spinner-border" role="status"></div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* info */}

      {/* data */}
      <div className="back-color-wite rounded-wd p-3 border">
        <div className="row ">
          <div className="col-md-6">
            <WebbDividerSmall />
            <p className="text-bold">Unit Data</p>

            <div className="mb-3">
              <label className="form-label small">
                Name <FormNeeded />
              </label>
              <input
                type="text"
                className="form-control height-md  "
                style={{ fontSize: "0.9rem", height: "2.7rem" }}
                value={data.name}
                onChange={({ target }) => {
                  handleChange("name", target.value);
                }}
                disabled={loading || submit}
                placeholder="unit name"
              ></input>
            </div>

            <div className="mb-3">
              <label className="form-label small">
                Description <FormNeeded />
              </label>
              <textarea
                className="form-control"
                rows="6"
                style={{ fontSize: "0.9rem" }}
                value={data.memo}
                onChange={({ target }) => {
                  handleChange("memo", target.value);
                }}
                disabled={loading || submit}
                placeholder="unit details"
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label small">
                Units (SQFT) <FormNeeded />
              </label>

              <input
                type="text"
                className="form-control height-md"
                style={{ fontSize: "0.9rem", height: "2.7rem" }}
                value={data.count}
                onChange={({ target }) => {
                  let inputValue = target.value;
                  if (inputValue.length > 0 && inputValue[0] === "0") {
                    inputValue = inputValue.replace(/^0+/, "");
                  }
                  const sanitizedValue = inputValue.replace(/[^\d]/g, "");
                  handleChange("count", sanitizedValue);
                }}
                onKeyPress={(event) => {
                  if (event.key === "." || event.key === ",") {
                    event.preventDefault();
                  }
                }}
                disabled={loading || submit}
                placeholder="123"
              />
            </div>

            <div className="mb-3">
              <label className="form-label small">
                Rate (Per Unit) <FormNeeded />
              </label>
              <input
                type="text"
                className="form-control height-md"
                style={{ fontSize: "0.9rem", height: "2.7rem" }}
                value={data.rate}
                onChange={({ target }) => {
                  let inputValue = target.value;
                  if (inputValue.length > 0 && inputValue[0] === "0") {
                    inputValue = inputValue.replace(/^0+/, "");
                  }
                  const sanitizedValue = inputValue.replace(/[^\d]/g, "");
                  handleChange("rate", sanitizedValue);
                }}
                onKeyPress={(event) => {
                  if (event.key === "." || event.key === ",") {
                    event.preventDefault();
                  }
                }}
                disabled={loading || submit}
                placeholder="123"
              />
            </div>

            <div className="mb-3">
              <label className="form-label small d-none">
                Currency <FormNeeded />
              </label>
              {creditmode &&
                creditmode.map((item, i) => (
                  <span className="me-1" key={i}>
                    <span
                      className={`${
                        data.ticker === item
                          ? "bg-secondary"
                          : "back-color-wite"
                      } p-2 px-3 text-white border rounded-wd text-uppercase text-small cursor`}
                      onClick={() => handleChange("ticker", item)}
                    >
                      {item}
                    </span>
                  </span>
                ))}
            </div>

            <WebbDividerMedium />
          </div>

          <div className="col-md-6">
            <WebbDividerSmall />
            <p className="text-bold m-0">Unit's Image</p>
            <p className="text-small mb-3">
              Media Dimensions 600 x 600 px <FormNeeded />
            </p>
            <FileCreateCX
              media={handleFile}
              accept="image/png, image/jpg, image/jpeg"
            />

            <WebbDividerMedium />
          </div>
        </div>
      </div>

      {/* action */}
      <WebbDividerMedium />
      <div className={!loading && submit && done ? "" : "d-none"}>
        <p>{memo}</p>
        <p
          className="cursor text-color-blue d-none"
          onClick={() => window.location.reload()}
        >
          Make Another Transfer
        </p>
      </div>

      <div className={loading && submit && !done ? "" : "d-none"}>
        Please Wait...
      </div>

      <div className={(loading && submit) || done ? "d-none" : ""}>
        <div className="d-flex justify-content-between">
          <button
            className={`btn btn-light border back-color-wite rounded-xx button text-small`}
            type="button"
            onClick={() => {
              navigate(`/${asset.role}/assets/${item}`);
            }}
          >
            {loading ? "Please Wait..." : "Cancel"}
          </button>

          <button
            className={`btn btn-primary rounded-xx text-small `}
            disabled={image == null || loading || submit}
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading ? "Please Wait..." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}
