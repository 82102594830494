import React, { useState } from "react";
import { useParams } from "react-router-dom";
import NavLineChart from "../webx/dashboard-navs-chart";
import AssetsTransactionList from "./assets-transaction-list";
import AssetUserListModule from "./assets-user-list";
import AssetUnitUserListModule from "./assets-units-user-list";
import AssetsUnitTransactionList from "./assets-unit-transaction-list";
import WebbDividerSmall from "../webx/webb-divider-sm";
import { OffersListCreator } from "../offers/my-offers-list";

export default function AssetUnitDetail() {
  const { item } = useParams();
  const [activeTab, setActiveTab] = useState("userList");

  const renderTabContent = () => {
    switch (activeTab) {
      case "transactionList":
        return <AssetsUnitTransactionList />;
      case "myOffersList":
        return <OffersListCreator />;
      case "userList":
        return <AssetUnitUserListModule />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="mb-4" style={{ height: "300px" }}>
        <p className="text-bold mx-3">Historical Rate (INR)</p>
        <NavLineChart unit={item} />
      </div>
      <WebbDividerSmall />
      <div className="mt-4">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link fw-bold ${
                activeTab === "userList" && "active"
              }`}
              onClick={() => setActiveTab("userList")}
            >
              Users
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link fw-bold ${
                activeTab === "myOffersList" && "active"
              }`}
              onClick={() => setActiveTab("myOffersList")}
            >
              My Offers
            </button>
          </li>
          <li className="nav-item d-none">
            <button
              className={`nav-link fw-bold ${
                activeTab === "transactionList" && "active"
              }`}
              onClick={() => setActiveTab("transactionList")}
            >
              Transactions
            </button>
          </li>
        </ul>
        <div className="tab-content mt-4">{renderTabContent()}</div>
      </div>
    </>
  );
}
