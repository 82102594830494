// auth - firebase mail link
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
// import { GetTransactionStatistics } from "../../services/srvc-transfers-realm";
import {
  TransferStatistics,
  TransfersGraphStatistics,
} from "../../services/srvc-transfers-realm";
import PolarAreaChart from "../webx/dashboard-areachart";
import LineChart from "../webx/dashboard-linechart";

const array = [
  {
    name: "Total",
    memo: "",
    icon: "bx bx-sort-alt-2",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Success",
    memo: "",
    icon: "bx bx-check-double",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Pending",
    memo: "",
    icon: "bx bx-radio-circle-marked",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "Failed",
    memo: "",
    icon: "bx bx-x",
    number: "",
    ticker: "***",
    rate: "",
    active: true,
  },
  {
    name: "",
    memo: "",
    icon: "bx bx-user-circle",
    number: "",
    ticker: "***",
    rate: "",
    active: false,
  },
  {
    name: "",
    memo: "",
    icon: "bx bx-user-circle",
    number: "",
    ticker: "***",
    rate: "",
    active: false,
  },
];

export default function TeamTransferStatisticsModule() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [currentItems, setCurrentItems] = useState([]);
  const [monthList, setMonthList] = useState([]);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await TransfersGraphStatistics({
        data: {
          creator: asset.item,
          fromDate: "2024-07-12",
          // toDate: "2023-01-01",
          status: "success",
        },
      });
      if (res) {
        console.log(res);
      }
      setCurrentItems(res?.data);
    const reversedMonthList = res?.data?.map(item => item._id.month).reverse();
    setMonthList(reversedMonthList);
    };

    fetchData();
  }, []);

  // console.log(listactions.filter(x => x.user.includes(asset.role)))

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        var res = await TransferStatistics({ data: { user: asset.item } });
        // const result = listactions.filter(x => x.user.includes(asset.role))
        // setData(result)
        for (let i = 0; i < array.length; i++) {
          if (array[i]["name"].toLocaleLowerCase() === "total") {
            // Update the object with new properties
            array[i] = { ...array[i], number: res?.data?.transaction?.total };
            // break; // Break the loop since we found the matching object
          }
          if (array[i]["name"].toLocaleLowerCase() === "success") {
            // Update the object with new properties
            array[i] = { ...array[i], number: res?.data?.transaction?.success };
            // break; // Break the loop since we found the matching object
          }
          if (array[i]["name"].toLocaleLowerCase() === "pending") {
            // Update the object with new properties
            array[i] = { ...array[i], number: res?.data?.transaction?.pending };
            // break; // Break the loop since we found the matching object
          }
          if (array[i]["name"].toLocaleLowerCase() === "failed") {
            // Update the object with new properties
            array[i] = { ...array[i], number: res?.data?.transaction?.failed };
            // break; // Break the loop since we found the matching object
          }
        }

        // const statx = Array.from(list, x =>{ return {
        //   ...x,
        //   number: (Math.random()*1000).toFixed(0),
        //   rate: (Math.random()*10).toFixed(0)
        // }})
        setData(array);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  const totalTransactions = currentItems.map((item) => item.totalTransaction);
const reversedTotalTransactions = [0, ...totalTransactions].reverse();
  const transfersData = {
    labels: [0, ...monthList],
    datasets: [
      {
        label: "Total",
        // data: [12,30, 0, 1,5,6],
        // data: currentItems.map(item => item.totalTransaction),
        data: reversedTotalTransactions,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        fill: "origin", // Fill area below the line
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Background color for the shaded area
      },
      // {
      //   label: "Failed",
      //   data: [2, 10, 0, 1,6,9],
      //   borderColor: "red",
      //   borderWidth: 2,
      //   fill: "origin",
      //   backgroundColor: "rgba(255, 0, 0, 0.2)",
      // },
      // {
      //   label: "Pending",
      //   data: [3, 0, 1, 4,2,4],
      //   borderColor: "orange",
      //   borderWidth: 2,
      //   fill: "origin",
      //   backgroundColor: "rgba(255, 165, 0, 0.2)",
      // },
      // {
      //   label: "Success",
      //   data: [5, 15, 2, 15,21,5],
      //   borderColor: "green",
      //   borderWidth: 2,
      //   fill: "origin",
      //   backgroundColor: "rgba(0, 128, 0, 0.2)",
      // },
    ],
  };

  const handleClick = (live, link) => {
    // if (live) navigate(`/${asset.role}/${link}`)
  };

  if (loader)
    return (
      <div className="text-center">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <div className="">
        <div className="row row-cols-2 row-cols-md-4 g-2">
          {data &&
            data.map((item, i) =>
              item.active ? (
                <div className="col" key={i}>
                  {/* large screen */}
                  <div
                    className={`back-color-wite rounded-xd p-3 w-100 h-100 border m-0 p-0 d-none d-md-block`}
                  >
                    <div className="d-flex">
                      <div className="">
                        <p className="text-small m-0">{item.name}</p>
                        <p className="text-lead text-bold m-0">
                          {item.number || "0"}
                        </p>
                      </div>
                      <div className="ms-auto ">
                        <i
                          className={`text-icon-md text-color-tint ${item.icon}`}
                        ></i>
                      </div>
                    </div>

                    <div className="mb-2"></div>

                    {/* <p className="m-0">
              <span><i className="bx bx-up-arrow-alt text-color-success"></i></span>
              <span>{item.rate || '0'}{'%'}</span>
            </p> */}
                  </div>
                  {/* small screen */}
                  <div className="back-color-wite rounded-xd py-2 px-1 w-100 h-100 border m-0 p-0 d-block d-md-none mb-2">
                    <div className="d-flex flex-column align-items-start px-2">
                      <p className="text-small m-0">{item.name}</p>
                      <div className="d-flex align-items-center">
                        <p className="text-lead text-bold m-0">
                          {item.number || "0"}
                        </p>
                        <i
                          className={`text-icon-md text-color-tint ${item.icon}`}
                          style={{ fontSize: "1.5em", marginLeft: "0.7rem" }}
                        ></i>
                      </div>
                    </div>

                    {/* <p className="m-0">
            <span><i className="bx bx-up-arrow-alt text-color-success"></i></span>
            <span>{item.rate || '0'}{'%'}</span>
          </p> */}
                  </div>
                </div>
              ) : (
                ""
              )
            )}
        </div>
        <WebbDividerSmall />
        <div style={{ height: "400px" }}>
          {currentItems.length > 0 && <LineChart data={transfersData} />}
        </div>
      </div>
    </>
  );
}
