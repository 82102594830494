// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'


export default function TeamInfocardHomeModule () {

  const navigate = useNavigate();
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  // console.log("Asset------------",asset)
  
  const [loader, setLoader] = useState(true);

  const [balance, setBalance] = useState({ number: '000000', ticker: '******' });
  const [data, setData] = useState()

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);

        var res = await AccountsBaseBalance({data:{user: asset.item}})
        // console.log(res)
        if(res.stat) setBalance(res?.data?.balance)

        setLoader(false);
      }
      fetchData()
    } else {}
  }, []);
  

  return (
  <>
    {/* info */}

    {/* data */}
   <div>
      <div className="rounded-xd text-color-wite"
        style={{
          backgroundImage:`url(${media})`, 
          backgroundRepeat:'no-repeat', 
          backgroundSize:'cover',
          backgroundPosition: 'center center',
          backgroundColor: 'rgba(0, 0, 0, 0.75)'
          // minHeight: '100vh'
          // height:'100vh'
        }}
      >
        <div className="rounded-xd p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.33)'}}>
  
          <div className="" style={{}}>
  
            <div className="" style={{height: '2.5rem', width: '2.5rem'}}>
              {/* <Jazzicon diameter={33} seed={jsNumberForAddress(asset.item ??= Date.now().toString())} />  */}
              <WebbIcon data={{ color: 'text-color-wite', size: 'text-icon-sm'}}/>
            </div>
          </div>      
  
          {/* <WebbDividerSmall /> */}
          <WebbDividerSmall />
          <p className="text-bold text-sm m-0">{asset?.number || '9999 0000 0000 0456'}</p>
        
          <WebbDividerSmall />
          <p className="text-small text-sm text-bold m-0">{asset?.name || '******'}</p>
          <p className="text-small text-sm m-0">{asset?.mail || '******'}</p>
          <p className="text-small m-0">Status: {asset?.active ? 'Active' : 'Inactive'}</p>
        </div>
  
      </div>
  
      <div className="text-small mt-1 text-color-lite mx-3 d-none">
        {asset?.item || '******'}
      </div>
  
      <WebbDividerSmall />
      <div className="back-color-wite p-3 rounded-xd border d-md-block d-none">
        <p className="text-small m-0">Account Balance</p>
        <p className="m-0">
          <span className="text-lead text-bold">{NumberFormat(balance?.number/1000000 ||'0','', '2')}</span>
          <span className="ms-1"></span>
          <span className="text-small">{balance?.ticker || '***'}</span>
        </p>
      </div>
   </div>

  </>

  )
}
