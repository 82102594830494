  import axios from "axios";
  import services from "../data.services/data-services-main.json";


  const base = services.data.find((x) => x.code === "assets").link;
  const mediabase = services.data.find((x) => x.code === "media").link;


// const mainbase = main_services.data.find((x) => x.code === "assets").link;


export const AssetsList = async (item) => {
  const basx = base + "/assets/list";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsDetail = async (item) => {
  const basx = base + "/assets/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsUnitsDetail = async (item) => {
  const basx = base + "/assets/units/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};



export const AssetsUnitLists = async (item) => {
  const basx = base + "/assets/units";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};


export const AssetsUnitEdit = async (item) => {
  const basx = base + "/units/edit";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};




export const AssetsUnitsDelete = async (item) => {
  const basx = base + "/assets/delete";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetsUnitsBook = async (item) => {
  const basx = base + "/assets/units/book";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetsUnitsLive = async (item) => {
  const basx = base + "/assets/units/golive";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};


export const AssetsShow = async (item) => {
  const basx = base + "/assets/show/set";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};


export const AssetsLive = async (item) => {
  const basx = base + "/assets/golive";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsScan = async (item) => {
  const basx = base + "/assets/scan/set";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsBook = async (item) => {
  const basx = base + "/assets/book/set";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsBrokerage = async (item) => {
  const basx = base + "/assets/broker/set";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};


export const AssetsUnitsActive = async (item) => {
  const basx = base + "/assets/units/status/set";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const DashboardAssetStatistics = async (item) => {
  const basx = base + "/dashboard/summary";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetSearch = async (item) => {
  const basx = base + "/assets/search";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetCreate = async (item) => {
  const basx = base + "/assets/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };
  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetDelete = async (item) => {
  const basx = base + "/assets/delete";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetMediaSave = async (item) => {
  const basx = mediabase + "/business/media/save";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};


export const AssetMediaDelete = async (item) => {
  const basx = mediabase + "/business/media/delete";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetDocumentSave = async (item) => {
  const basx = mediabase + "/media/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetUnitsCreates = async (item) => {
  const basx = base + "/units/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };
  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetMediaList = async (item) => {
  const basx = mediabase + "/documents/list/asset/media";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetDocumentList = async (item) => {
  const basx = mediabase + "/media/list";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetDocumentDelete = async (item) => {
  const basx = mediabase + "/media/delete";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsBrokersList = async (item) => {
  const basx = base + "/assets/brokers/list";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
export const AssetsBrokerAdd = async (item) => {
  const basx = base + "/assets/broker/add";
  const head = {
    "Content-Type": "application/json",
    Authorization: "*******",
    //  process.env.REACT_APP_WEBB_SITE_CLNT
  };
  const datx = {
    data: item.data,
    srvc: "657a8b72a5ae40d787873c7267ac2a2f5",
    // process.env.REACT_APP_WEBB_SITE_SRVC
  };

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return { code: result.status, ...result.data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};