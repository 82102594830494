import React, { useEffect, useState } from "react";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import { Link, useParams } from "react-router-dom";
import { AssetsUserList } from "../../services/srvc-transfers-assets-realm.js";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { AssetsBrokersList } from "../../services/srvc-assets-realm.js";

export default function AssetBrokerListModule() {
  const { item } = useParams();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [feex, setFeex] = useState("0")

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await AssetsBrokersList({
        data: {
          asset: item,
        },
      });
      setCurrentItems(
        res?.data.brokers.sort((a, b) => b.name - a.name)
      );
      setFeex(
        res?.data.feex
      );
      setLoader(false);
    };

    fetchData();
  }, []);

  if (loader == true) {
    return (
      <p className="mt-4 d-flex justify-content-center">Please wait ....</p>
    );
  }
  return (
    <>
      {currentItems.length > 0 ? (
        <div className="border rounded  border-none mb-4">
          {currentItems.map((item, index) => (
            <div key={item.item}>
              <div className="d-flex justify-content-between flex-column flex-md-row bg-white p-4 ">
                <div className="d-flex gap-3">
                  <Jazzicon />
                  <div>
                    <p
                      className="m-0 text-primary "
                      style={{ fontWeight: "bold" }}
                    >
                      {item?.name || ""}
                    </p>
                    <p className="m-0 text-small">{item?.mail || ""}</p>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center
                gap-2"
                >
                  <div className=" d-flex flex-column  align-items-center">
                    {/* <p className="m-0">Status</p> */}
                    <p className="m-0" style={{ fontWeight: "bold" }}>
                      <span >
                        {feex?.number || "******"}{" "}
                      </span>
                      { "%" || feex?.ticker?.toUpperCase()}
                    </p>
                  </div>
                  {/* <i className="bx bx-chevron-right bx-sm"></i> */}
                </div>
              </div>

              <hr className="m-0" />
            </div>
          ))}
        </div>
      ) : (
        <div className="border rounded p-4 "> No Users found.....</div>
      )}
    </>
  );
}
